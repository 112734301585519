import React from 'react';
import { Plan } from '@app/types/Plan';
import { FormattedMessage } from 'react-intl';

/** Dummy Plan data. */
export const samplePlanData: Plan[] = [
  {
    name: 'landingPage.plans.player.title',
    featuresArray: [
      'landingPage.plans.player.feature1',
      'landingPage.plans.player.feature2',
      'landingPage.plans.player.feature3',
      'landingPage.plans.player.feature4',
    ],
    descriptionHtml: (
      <span>
        <p>
          <FormattedMessage id="landingPage.plans.player.text1" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.player.text2" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.player.text3" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.player.text4" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.player.text5" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.player.text6" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.player.text7" />
        </p>
      </span>
    ),
    id: 1,
    paypalPlanId: '1',
    active: true,
    dailyPrice: 27.99,
    durationInMonths: 1,
    price: 60.99,
    text: 'testando',
  },
  {
    name: 'landingPage.plans.clubs.title',
    featuresArray: [
      'landingPage.plans.clubs.feature1',
      'landingPage.plans.clubs.feature2',
      'landingPage.plans.clubs.feature3',
      'landingPage.plans.clubs.feature4',
      'landingPage.plans.clubs.feature5',
    ],
    descriptionHtml: (
      <span>
        <p>
          <FormattedMessage id="landingPage.plans.clubs.text1" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.clubs.text2" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.clubs.text3" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.clubs.text4" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.clubs.text5" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.clubs.text6" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.clubs.text7" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.clubs.text8" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.clubs.text9" />
        </p>
      </span>
    ),
    id: 2,
    paypalPlanId: '2',
    active: true,
    dailyPrice: 27.99,
    durationInMonths: 1,
    price: 60.99,
    text: 'testando',
  },
  {
    name: 'landingPage.plans.agents.title',
    featuresArray: [
      'landingPage.plans.agents.feature1',
      'landingPage.plans.agents.feature2',
      'landingPage.plans.agents.feature3',
      'landingPage.plans.agents.feature4',
      'landingPage.plans.agents.feature5',
    ],
    descriptionHtml: (
      <span>
        <p>
          <FormattedMessage id="landingPage.plans.agents.text1" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.agents.text2" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.agents.text3" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.agents.text4" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.agents.text5" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.agents.text6" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.agents.text7" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.agents.text8" />
        </p>
        <p>
          <FormattedMessage id="landingPage.plans.agents.text9" />
        </p>
      </span>
    ),
    id: 1,
    paypalPlanId: '1',
    active: true,
    dailyPrice: 27.99,
    durationInMonths: 1,
    price: 60.99,
    text: 'testando',
  },
];
